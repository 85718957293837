import React from "react";
import { PropTypes } from 'prop-types';
import { toAnt } from "./functions";

const Button = ({handleClick, text}) => {
	return <button onClick={ handleClick } className="io small-item">{ text }</button>
};

Button.propTypes = {
	handleClick: PropTypes.func.isRequired,
	text: PropTypes.string,
}

Button.defaultProps = {
	text: 'placeholder',
}

const InputField = props => {
	return (
		<textarea type="text" value={props.text}
			onChange={ props.handleChange }
			className="io large-item"
		/>
	);
}

InputField.propTypes = {
	text: PropTypes.string,
	handleChange: PropTypes.func.isRequired
};

InputField.defaultProps = {
	text: ''
};

const OutputField = props => {
	const textArea = React.useRef(null);
	
	const copyToClipboard = e => {
		textArea.current.select();
		document.execCommand('copy');
	}

	return (
		<div className="output">
			<textarea ref={ textArea }
			className="io large-item capital-tracking"
			value={ props.translation }
			readOnly/>
			<Button  handleClick={ copyToClipboard } text={ toAnt('copy') } />
		</div>
	);
}

OutputField.propTypes = {
	translation: PropTypes.string
};

OutputField.defaultProps = {
	translation: ''
};

export { InputField, OutputField, Button };