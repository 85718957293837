import React from 'react';
import App from '../components/app';

// import style
import '../style/index.scss';
// import the SEO component
import SEO from '../components/seo';

export default () => {
	// grab the url if possible
	return (<>
		<SEO title={'home'}/>
		<App />
	</>);
}