import React from "react";
import { PropTypes } from 'prop-types';
import { toAnt } from './functions';

const Dropdown = ({ name, options, handleChange }) => {
	return (
		<select id={ name } className="io small-item"
		name={ name }
		onChange={ handleChange }>
			{ options.map(val => {
				const val_key = val.replace(/\s/g, '_');
				return <option value={ val_key }
				key={ "dropdown_" + val_key }>
					{ toAnt(val) }
				</option>
			})}
		</select>
	)
}

Dropdown.propTypes = {
	name: PropTypes.string.isRequired,
	options: PropTypes.arrayOf(PropTypes.string).isRequired,
	handleChange: PropTypes.func.isRequired
}

const SpacingDropdown = ({handleChange}) => {
	const name = "spacing between words";
	const options = [ 'monospace', 'double space' ];
	return (<React.Fragment>
		<label htmlFor={ name } className="capital-tracking">{ toAnt(name) }</label>
		<Dropdown name={ name }
		options={ options }
		handleChange={ handleChange } />
	</React.Fragment>);
}
SpacingDropdown.propTypes = {
	handleChange: PropTypes.func.isRequired
}

// const PunctuationDropdown = ({handleChange}) => {
// 	const name = "punctuation";
// 	const options = [ 'yes', 'yes but not apostrophes', 'no' ];
// 	return (<React.Fragment>
// 		<label htmlFor={ name }>{ toAnt('add spaces to punctuation') }</label>
// 		<Dropdown name={ name }
// 		options={ options }
// 		handleChange={ handleChange } />
// 	</React.Fragment>);
// }
// PunctuationDropdown.propTypes = {
// 	handleChange: PropTypes.func.isRequired
// }

const AntReplacementDropdown = ({handleChange}) => {
	const name = 'make it more ant-ey';
	const options = [ 'no', 'yes' ];
	return (<React.Fragment>
		<label htmlFor={ name } className="capital-tracking">{ toAnt(name) }</label>
		<Dropdown name={ name }
		options={ options }
		handleChange={ handleChange } />
	</React.Fragment>);
}
AntReplacementDropdown.propTypes = {
	handleChange: PropTypes.func.isRequired
}



const Options = ({handleChangeSpacing,
	handleChangePunctuation,
	handleChangeAnt }) => {
	return (<div className="options">
		<SpacingDropdown handleChange={ handleChangeSpacing } />
		<AntReplacementDropdown handleChange={ handleChangeAnt } />
    </div>);
}
Options.propTypes = {
	handleChangeSpacing: PropTypes.func.isRequired,
	// handleChangePunctuation: PropTypes.func.isRequired,
	handleChangeAnt: PropTypes.func.isRequired,
}

export default Options;