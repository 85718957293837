// Main App
import React from 'react';
import { Link } from 'gatsby';

import { InputField, OutputField, Button } from './io_components';
// import the header
import Header from './header';
import Footer from './footer';
import Options from './options';
import { toAnt } from './functions';

class App extends React.Component {
	constructor(props) {
		super(props);
		
		const getPlaceholder = () => {
			const placeholderOptions = [
				'bite',
				'lift',
			];

			const rand = Math.floor(Math.random() * placeholderOptions.length)

			return toAnt(placeholderOptions[rand]);
		}

		this.state = {
			inputText: '',
			translatedText: getPlaceholder(),
			showOptions: false,
			spacingSetting: 'monospace',
			antReplacementSetting: 'no',
		}
	}

	handleChange = e => {
		this.setState({
			inputText: e.target.value
		});
	}

	handleChangeSelectElement = ( e, stateValue ) => {
		let new_state = {};
		new_state[stateValue] = e.target.value;
		this.setState(new_state);
	}

	toggleOptions = e => {
		// open or close the options
		const toggle_state = !this.state.showOptions;
		this.setState({
			showOptions: toggle_state
		});
	}

	translate = e => {
		const spacing = this.state.spacingSetting;
		const antReplacement = this.state.antReplacementSetting;
		let translation = this.state.inputText;
		
		// helper function that splits words based on the translation settings
		const replacementRules = word => {
			
			console.log('replacement func');
			// also, substitute "ant" as appropriate
			// first, only if the setting is set to do so
			if(antReplacement === "yes") {
				const blacklist = {
					'AN': true ,'ANT': true, 'EAT': true, 'CAT': true, 'THAT': true,
					'ATE': true, 'MAD': true, 'WATER': true, 'WATERS': true, 'AT': true,
					'ANTHILL': true
					
				}
				if(!(word in blacklist)
				&& word.search(/EAT|EAD/g) === -1
				&& word.search(/ATC/g) === -1) {
					word = word.replace(/AT|AD|AND|AN/g, 'ANT');
					// fix the ending mixup this creates on abbreviations
					word = word.replace('t\'t', '\'t');
					// fix other letter doubling
					word = word.replace('ANTT', 'ANT');
					// // EAT/EAD shouldn't be substituteD
					// if there is a th, reverse the ant-ing
					word = word.replace('ANTH', 'ATH');
				}
			}


			return word;
		}

		// set the spacing
		const space = spacing === 'monospace' ? ' ' : '  ';

		translation = toAnt(translation, space, replacementRules);

		this.setState({
			translatedText: translation
		});
	}

	render() {
		return (
		<div className="app">
			<Header />
			<div className="input">
				<InputField text={ this.state.inputText } handleChange={ this.handleChange } />
				<Button handleClick={ this.toggleOptions } text={ toAnt("antvanced options") } />
				{ this.state.showOptions
					&& <Options 
					handleChangeSpacing={ e => this.handleChangeSelectElement(e, 'spacingSetting') }
					handleChangePunctuation={ e => this.handleChangeSelectElement(e, 'punctuationSetting') }
					handleChangeAnt={ e => this.handleChangeSelectElement(e, 'antReplacementSetting') }
				/> }
				<Button handleClick={ this.translate } text={ toAnt('trantslate') }/>
			</div>
			<OutputField translation={ this.state.translatedText } />
			<Footer />
		</div>);
	}
}

export default App;